<template>
  <CDropdown
    ref="dropdown"
    color="primary"
    :toggler-text="$t('network.actions.title')"
    class="actions-list"
    addMenuClasses="dropdown-area"
  >
    <div
      class="actions-list__item item"
      :disabled="!canEditAndSign"
      @click="handleActionClick('signTransaction')"
      @keyup.enter="handleActionClick('signTransaction')"
      @keyup.space="handleActionClick('signTransaction')"
      :tabindex="!canEditAndSign ? -1 : 0"
    >
      <p class="item__title">
        {{ $t("network.actions.signTransaction.name") }}
      </p>
      <p class="item__description">
        {{ $t("network.actions.signTransaction.description") }}
      </p>
    </div>
    <div
      class="actions-list__item item"
      :disabled="!canEditAndSign"
      @click="handleActionClick('changeNetwork')"
      @keyup.enter="handleActionClick('changeNetwork')"
      @keyup.space="handleActionClick('changeNetwork')"
      :tabindex="!canEditAndSign ? -1 : 0"
    >
      <p class="item__title">{{ $t("network.actions.changeNetwork.name") }}</p>
      <p class="item__description">
        {{ $t("network.actions.changeNetwork.description") }}
      </p>
    </div>
    <div
      class="actions-list__item item"
      @click="handleActionClick('updateConfiguration')"
      tabindex="0"
      @keyup.enter="handleActionClick('updateConfiguration')"
      @keyup.space="handleActionClick('updateConfiguration')"
    >
      <p class="item__title">
        {{ $t("network.actions.updateConfiguration.name") }}
      </p>
      <p class="item__description">
        {{ $t("network.actions.updateConfiguration.description") }}
      </p>
    </div>
    <div
      class="actions-list__item item"
      :disabled="!isUserPartOfOsnProvider"
      @click="handleActionClick('downloadConfiguration')"
      @keyup.enter="handleActionClick('downloadConfiguration')"
      @keyup.space="handleActionClick('downloadConfiguration')"
      :tabindex="!isUserPartOfOsnProvider ? -1 : 0"
    >
      <p class="item__title">
        {{ $t("network.actions.downloadConfiguration.name") }}
      </p>
      <p class="item__description">
        {{ $t("network.actions.downloadConfiguration.description") }}
      </p>
    </div>
    <div
      class="actions-list__item item"
      @click="handleActionClick('uploadNetworkMap')"
      :disabled="!isUpdateHostsAvailable"
      @keyup.enter="handleActionClick('downloadConfiguration')"
      @keyup.space="handleActionClick('downloadConfiguration')"
      :tabindex="!isUpdateHostsAvailable ? -1 : 0"
    >
      <p class="item__title">
        {{ $t("network.actions.uploadNetworkMap.name") }}
      </p>
      <p class="item__description">
        {{ $t("network.actions.uploadNetworkMap.description") }}
      </p>
    </div>
  </CDropdown>
</template>

<script>
import { ChannelConfigUpdateType } from "../common/transaction/constants";
import { mapGetters } from "vuex";

export default {
  name: "NetworkActions",
  props: {
    network: Object,
  },
  data() {
    return {
      dropdown: null,
    };
  },
  mounted() {
    this.dropdown = this.$refs.dropdown;
  },
  computed: {
    ...mapGetters(["getUser", "isUpdateHostsAvailable"]),
    canEditAndSign() {
      return (
        this.isNetworkRunning &&
        this.isNetworkSuccessfullyUpdated &&
        this.isCurrentUserCanEditNetwork
      );
    },
    isNetworkRunning() {
      return this.network.status === "started";
    },
    isNetworkSuccessfullyUpdated() {
      return this.network.update_status === "success";
    },
    isCurrentUserCanEditNetwork() {
      const canUserEditNetwork =
        this.network === ChannelConfigUpdateType.EXCLUSIVE
          ? this.isTheUserAnAdministrator
          : true;

      return this.isUserPartOfOsnProvider && canUserEditNetwork;
    },
    isUserOsnProvider() {
      return this.getUser.org_id === this.network.network_administrator_org_id;
    },
    isUserPartOfOsnProvider() {
      const userId = this.getUser.org_id;
      return this.network.orderer_organizations.some(
        (item) => item.id === userId
      );
    },
  },
  methods: {
    handleActionClick(actionType) {
      this.hideDropdown();
      this.$emit("actionRequested", actionType);
    },
    hideDropdown() {
      if (!this.dropdown) {
        return;
      }

      this.dropdown.hide();
    },
  },
};
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  color: #455164;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 20px;

  min-width: 600px;

  user-select: none;
}

.item[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

.item:hover {
  background-color: #ebedef;
}

.item:active {
  color: #fff;
  background-color: #321fdb;
}

.item__title,
.item__description {
  padding: 0 !important;
  margin: 0 !important;
}

.item__description {
  color: #959595;
}

.item__desctiption:active {
  color: rgb(235, 235, 235);
}
</style>
