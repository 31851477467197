<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CCard v-if="network">
        <CCardHeader>
          <div class="header d-flex align-items-center justify-content-between">
            <div class="header__title">
              <strong>{{ $t("network.item") }} {{ network.name }}</strong>
            </div>
            <div class="header__actions">
              <network-actions
                :network="network"
                @actionRequested="handleRequestedAction"
              />
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold">{{
              $t("network.type")
            }}</CCol>
            <CCol sm="9">
              <img :src="getTypeSrc(network.version)" style="height: 24px" />
              <span class="ml-2">{{ network.version }}</span>
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold">{{
              $t("network.dateTime")
            }}</CCol>
            <CCol sm="9">
              <span class="ml-2">{{
                network.creation_date | formatDate($t("network.absent"))
              }}</span>
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold">{{
              $t("network.politics")
            }}</CCol>
            <CCol sm="9">
              <span class="ml-2">{{
                netPolicy[network.blockchain_update_policy_type].text
              }}</span>
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold mb-2">
              {{ $t("network.osnProviders") }}
            </CCol>
            <CCol sm="9">
              <ObjectsCollapse
                :net-id="network.id"
                :items="network.orderer_organizations"
                :hosts="extraHosts"
                :networkAdminId="network.network_administrator_org_id"
                :itemNameAttr="['name', 'mspid']"
                objectType="organization_orderer"
              />
            </CCol>
          </CRow>

          <CRow class="object-card-row">
            <CCol sm="3" class="font-weight-bold mb-2">
              {{ $t("network.participants") }}
            </CCol>
            <CCol sm="9">
              <ObjectsCollapse
                :net-id="network.id"
                :items="network.consortium"
                :hosts="extraHosts"
                :itemNameAttr="['name', 'mspid']"
                objectType="organization_peer"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <ImportNetworkConfiguration
      :show="displayImportDialog"
      :networkId="network?.id"
      @closeModal="closeImportNetworkDialog"
    ></ImportNetworkConfiguration>

    <SignTransactionModal
      v-if="network"
      :isShow="showTransactionSignModal"
      :name="network.code_name"
      @onClose="closeSignTransactionModal"
    />

    <EndpointsUpdateModal
      :isOpened="endpointsUpdateModal"
      @onClose="closeEndpointUpdateModal"
    />
  </CRow>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import moment from "moment";

import { createFileNameWithDate, saveFile } from "@/utils/system";
import utilMessages from "../../../utils/messages";
import SignTransactionModal from "../common/SignTransaction";
import ObjectsCollapse from "../components/ObjectsCollapse.vue";
import { BlockchainConfigUpdateType, NetworkTypes, NetworkUpdateStatus } from "./constants";
import data from "./data/NetworkActive";
import EndpointsUpdateModal from "./EndpointsUpdateModal.vue";
import ImportNetworkConfiguration from "./ImportNetworkConfiguration";
import NetworkActions from "./NetworkActions.vue";

export default {
  name: "Network",
  components: {
    ObjectsCollapse,
    SignTransactionModal,
    ImportNetworkConfiguration,
    NetworkActions,
    EndpointsUpdateModal,
  },
  data() {
    return {
      netTypes: NetworkTypes,
      netPolicy: BlockchainConfigUpdateType,
      netUpdateStatus: NetworkUpdateStatus,
      netId: null,
      network: null,
      item: data,
      displayImportDialog: false,
      showTransactionSignModal: false,
      endpointsUpdateModal: false,
    };
  },
  async mounted() {
    if (!this.$route.params.id) {
      this.$router.push({ path: "/dashboard" });
    }

    this.netId = this.$route.params.id;
    await this.fetchNetworks();
    await this.getNetwork();

    if (this.network.update_status !== this.netUpdateStatus.success.code) {
      this.$router.push({ path: `/networks/${this.netId}/draft` });
    }

    await this.fetchHosts();
    await this.fetchEndpoints();
  },
  filters: {
    formatDate: function (date, defaultValue) {
      if (!date) {
        return defaultValue;
      }

      return moment(date).format("DD.MM.YYYY HH:mm");
    },
  },
  computed: {
    ...mapGetters(["extraHosts", "getUser"]),
  },
  methods: {
    ...mapActions(["fetchNetworks"]),
    async getNetwork() {
      this.network = this.$store.getters.getNetwork(this.netId);
      if (!this.network) {
        try {
          await this.$store.dispatch("fetchNetworks", { view: "organization" });
          this.network = this.$store.getters.getNetwork(this.netId);

          if (!this.network) {
            throw new Error(
              `${this.$i18n.t("network.networkNotFound")} (${item.id})`
            );
          }
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    getTypeSrc(netType) {
      switch (netType) {
        case this.netTypes.GOSMART10.code:
          return this.netTypes.GOSMART10.src;
        case this.netTypes.HLF20.code:
          return this.netTypes.HLF20.src;
        case this.netTypes.HLF14.code:
          return this.netTypes.HLF14.src;
        case this.netTypes.HLF22.code:
          return this.netTypes.HLF22.src;
      }
    },
    getDateFormatted(dt) {
      return moment(dt).format("DD.MM.YYYY HH:mm");
    },
    changeNetwork() {
      this.$router.push({ path: `/networks/${this.netId}/edit` });
    },
    async fetchHosts(update = false) {
      if (!this.$store.getters.getExtraHosts(this.netId) || update) {
        try {
          await this.$store.dispatch("fetchExtraHosts", this.netId);
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    async fetchEndpoints() {
      try {
        await this.$store.dispatch("fetchEndpoints");
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async downloadConf() {
      try {
        const result = await this.$store.dispatch(
          "exportNetwork",
          this.network.id
        );
        const content = JSON.stringify(result);
        const name = createFileNameWithDate(result.code_name);
        saveFile(content, name);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    openNetworkImportDialog() {
      this.displayImportDialog = true;
    },
    closeImportNetworkDialog() {
      this.displayImportDialog = false;
    },
    handleRequestedAction(actionCode) {
      switch (actionCode) {
        case "signTransaction":
          this.openSignTransactionModal();
          break;
        case "changeNetwork":
          this.changeNetwork();
          break;
        case "updateConfiguration":
          this.openNetworkImportDialog();
          break;
        case "downloadConfiguration":
          this.downloadConf();
          break;
        case "uploadNetworkMap":
          this.openEndpointsUpdateModal();
          break;
        default:
          throw new Error(
            `Requested not supported action with code: ${actionCode}`
          );
      }
    },
    openSignTransactionModal() {
      this.showTransactionSignModal = true;
    },
    closeSignTransactionModal() {
      this.showTransactionSignModal = false;
    },
    openEndpointsUpdateModal() {
      this.endpointsUpdateModal = true;
    },
    closeEndpointUpdateModal() {
      this.endpointsUpdateModal = false;
    },
  },
};
</script>
<style>
.object-card-row {
  margin-bottom: 20px;
}

.object-card-row div {
}

.collapse_item_header {
  cursor: pointer;
}

.collapse_item_header.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px)
    calc(0.25rem - 1px);
}
</style>
