/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


// TODO: Тестовые данные, думаю стоит их удалить
const default_org = {
  ca: {
    "cn": "ca.gazprom-neft.ru",
    "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a9",
    "path": "#"
  },
  intermediatecerts: [
    {
      "cn": "subca1.gazprom-neft.ru",
      "fingerprint": "63:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a2",
      "path": "#"
    },
    {
    "cn": "subca2@gazprom-neft.ru",
    "fingerprint": "83:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a3",
    "path": "#"
    }
  ],
  tlsca: {
    "cn": "tlsca.gazprom-neft.ru",
    "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a8",
    "path": "#"
  },
  intermediatetlscerts: [
    {
      "cn": "subtlsca1.gazprom-neft.ru",
      "fingerprint": "63:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a2",
      "path": "#"
    },
    {
    "cn": "subtlsca2@gazprom-neft.ru",
    "fingerprint": "83:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a3",
    "path": "#"
    }
  ],
  admincerts: [
    {
      "cn": "admin1@gazprom-neft.ru",
      "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a2",
      "path": "#"
    },
    {
    "cn": "admin2@gazprom-neft.ru",
    "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a3",
    "path": "#"
    }
  ]
}

const default_peers = [
  {
    host: "peer1.gazprom-neft.ru",
    dns: "global",
    connected: false,
    ip: ""
  },
  {
    host: "peer2.afsc.aero",
    dns: "local",
    connected: true,
    ip: "188.124.38.67"
  }
];

const data =
  {
    "id": "network0",
    "created": "2020-09-29T19:41:58.5896511Z",
    "updated": "2020-09-29T19:42:19.8752359Z",
    "name": "SmartFuel PreProd",
    "type": "gosmart",
    "organizations_peer": [
      {
        mspid: "GPNAMSP"
      },
      {
        mspid: "SmartaviaMSP"
      },
      {
        mspid: "VTBMSP"
      },
      {
        mspid: "RaiffeisenMSP"
      }
    ],
    "organizations_orderer": [
      {
        mspid: "GPNAOrdererMSP",
        orderers: [
          {
            host: "orderer1.afsc.aero",
            tlscert: {
              cn: "orderer1.afsc.aero",
              "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a8",
              "path": "#"
            },
            dns: "global",
            connected: false,
            ip: ""
          },
          {
            host: "orderer2.afsc.aero",
            tlscert: {
              cn: "orderer2.afsc.aero",
              "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a8",
              "path": "#"
            },
            dns: "local",
            connected: true,
            ip: "188.124.38.67"
          }
        ]
      },
      {
        mspid: "SmartaviaMSP",
        orderers: [
          {
            host: "orderer1.afsc.aero",
            tlscert: {
              cn: "orderer1.afsc.aero",
              "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a8",
              "path": "#"
            },
            dns: "global",
            connected: false,
            ip: ""
          },
          {
            host: "orderer2.afsc.aero",
            tlscert: {
              cn: "orderer2.afsc.aero",
              "fingerprint": "43:51:43:a1:b5:fc:8b:b7:0a:3a:a9:b1:0f:66:73:a8",
              "path": "#"
            },
            dns: "local",
            connected: true,
            ip: "188.124.38.67"
          }
        ]
      }
    ],
    "policy": "majority",
    "status": "active"
  };

data.organizations_peer.forEach((item, i) => {
  data.organizations_peer[i] = Object.assign( {}, default_org, item)
  data.organizations_peer[i].peers = default_peers;
});

data.organizations_orderer.forEach((item, i) => {
  data.organizations_orderer[i] = Object.assign( {}, default_org, item)
});

export default data
