<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div class="object-collapse-wrapper">
    <CCard v-for="(org, i) in items" class="mb-2"
      :accentColor="accordion === i ? 'primary' : ''" 
      :key="org.mspid">

      <CCardHeader class="border-bottom-0 object-collapse__header" 
        @click="accordion = accordion === i ? false : i">
        <strong>{{ getOrgName(org) }}</strong>

        <CBadge class="ml-2" color="primary" v-if="org.id === networkAdminId">
          {{ $t('network.admin') }}
        </CBadge>

        <div class="card-header-actions">
          <CButton size="sm" class="p-0 m-0">
            <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />
            <fa-icon icon="angle-down" size="lg" v-if="accordion !== i" />
          </CButton>
        </div>
      </CCardHeader>

      <CCollapse :show="accordion === i">
        <CCardBody v-if="hosts && accordion === i" class="border-top">
          <NetworkOrg v-if="isOrdererOrPeer" 
            :net-id="netId"
            :ordererOrganization="isOrderer" 
            :org="org" 
            :org-hosts="hosts[org.id]"
            :isForChannels="isForChannels" />
        </CCardBody>
      </CCollapse>
      
    </CCard>
  </div>
</template>

<script>
import NetworkOrg from "../components/NetworkOrg.vue";

export default {
  name: 'ObjectsCollapse',
  components: {
    NetworkOrg
  },
  props: {
    netId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    hosts: {
      type: Object,
    },
    itemNameAttr: {
      type: Array,
      default: ['name'],
    },
    objectType: String,
    isForChannels: {
      type: Boolean,
      default: false,
    },
    networkAdminId: String
  },
  data() {
    return {
      accordion: false
    }
  },
  computed: {
    isOrdererOrPeer() {
      return this.isOrderer || this.objectType === 'organization_peer';
    },
    isOrderer() {
      return this.objectType === 'organization_orderer';
    },
  },
  methods: {
    getOrgName(org) {
      for (let i = 0; i < this.itemNameAttr.length; i++) {
        const propName = this.itemNameAttr[i];
        
        if (org[propName]) {
          return org[propName];
        }
      }
    }
  }
};
</script>

<style type="scss">
.object-collapse__header {
  cursor: pointer;
}
</style>
