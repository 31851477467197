<template>
  <CModal
    :show="isOpened"
    :closeOnBackdrop="false"
    :closeButton="false"
    size="sm"
  >
    <div slot="header" class="header">
      <h5>Выгрузка сетевой карты</h5>
    </div>

    <ul class="endpoints">
      <li class="endpoint" v-for="endpoint in endpoints" :key="endpoint.id">
        <span
          class="host-name"
          :title="`${endpoint.hostname}:${endpoint.port}`"
        >
          {{ endpoint.hostname }}:{{ endpoint.port }}
        </span>

        <div class="actions">
          <div v-if="!endpoint.updateStatus.loading">
            <fa-icon
              v-if="!endpoint.updateStatus.success"
              icon="times"
              size="1x"
              style="color: red"
              class="mr-2"
            />
            <fa-icon
              v-if="endpoint.updateStatus.success"
              icon="check"
              size="1x"
              style="color: green"
            />
          </div>

          <fa-icon
            v-if="!endpoint.updateStatus.success"
            icon="sync"
            size="1x"
            @click="update(endpoint)"
            :class="{ animated: endpoint.updateStatus.loading }"
            :style="{
              color: endpoint.updateStatus.loading ? 'orange' : 'black',
            }"
            class="update-icon"
          />
        </div>
      </li>
    </ul>

    <div slot="footer">
      <CButton
        type="button"
        class="btn btn-secondary mr-1"
        @click="closeModal()"
      >
        {{ $t("common.close") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import utilMessages from "../../../utils/messages";

export default {
  name: "EndpointsUpdateModal",
  props: {
    isOpened: Boolean,
  },
  data() {
    return {
      endpoints: [],
    };
  },
  methods: {
    ...mapActions([
      "fetchServices",
      "updateEndpoint",
      "updateIsUpdateHostsAvailable",
      "updateHostsUpdateStatus",
      "subscribeTopic",
      "subscribe",
    ]),
    closeModal() {
      this.endpoints = [];
      this.$emit("onClose");
    },
    makeEndpointsList() {
      const hostsUpdateStatus = this.hostsUpdateStatus;

      return this.allServices
        .filter((item) => item.id)
        .reduce((state, item) => [...state, ...item.endpoints], [])
        .map((endpoint) => {
          const status = hostsUpdateStatus
            ? hostsUpdateStatus[endpoint.id]
            : false;
          const updateStatus = status
            ? { loading: false, success: true }
            : { loading: true, success: null };

          return {
            ...endpoint,
            ...{ updateStatus },
          };
        });
    },
    async initComponent() {
      try {
        this.loading = true;
        await this.fetchServices();

        this.endpoints = this.makeEndpointsList();

        const responses = await Promise.all(
          this.endpoints
            .filter((item) => !item.updateStatus.success)
            .map((endpoint) => this.updateEndpoint(endpoint.id))
        ).catch((error) => {
          this.$toast.error(utilMessages.errMessage(error));
        });

        responses.forEach(this.handleResponse);
      } finally {
        this.loading = false;
      }
    },
    update(endpoint) {
      if (endpoint.updateStatus.loading) {
        return;
      }

      this.$set(endpoint, "updateStatus", {
        ...endpoint.updateStatus,
        loading: true,
      });

      this.updateEndpoint(endpoint.id).then(this.handleResponse);
    },
    handleResponse(response) {
      if (response.error) {
        this.updateEndpointStatus(response);
        return;
      }

      const { endpointId, topicId } = response;

      this.subscribe({
        key: topicId,
        handler: this.updateEndpointStatus,
        extraPayload: { endpointId },
      });
    },
    updateEndpointStatus({ endpointId, success, error }) {
      const endpoint = this.endpoints.find((item) => item.id === endpointId);
      if (!endpoint) {
        return;
      }

      this.$set(endpoint, "updateStatus", { loading: false, success });

      if (error) {
        this.$toast.error(utilMessages.errMessage(error));
      }

      this.updateLocalStorage(endpointId, success);
    },
    updateLocalStorage(endpointId, success) {
      if (this.allUpdated) {
        this.updateHostsUpdateStatus(null);
        this.updateIsUpdateHostsAvailable(false);
        return;
      }
      const hostsUpdateStatus = this.hostsUpdateStatus ?? {};
      hostsUpdateStatus[endpointId] = success;
      this.updateHostsUpdateStatus(hostsUpdateStatus);
    },
  },
  computed: {
    ...mapGetters([
      "allServices",
      "isUpdateHostsAvailable",
      "hostsUpdateStatus",
    ]),
    allUpdated() {
      return this.endpoints.every((item) => item.updateStatus.success);
    },
  },
  watch: {
    isOpened(isOpened) {
      if (!isOpened) {
        return;
      }

      this.initComponent();
    },
  },
};
</script>

<style scoped>
.endpoints {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  list-style: none;

  user-select: none;
}

.endpoint:not(:last-child) {
  margin-bottom: 10px;
}
.endpoint {
  display: flex;
  justify-content: space-between;
}
.actions {
  display: flex;
  align-items: center;
}

.update-icon {
  cursor: pointer;
}

.host-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-right: 10px;
}
</style>
