<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    <div class="organization__buttons">
      <CLink @click="addPeer()" class="float-right"
        >{{ $t("organizations.addPeer") }} +</CLink
      >
    </div>

    <CModal
      :title="$t('organizations.titlePeer')"
      :show.sync="isPeerFormShowed"
    >
      <CInput
        v-model.trim="peerData.hostname"
        horizontal
        label="Hostname*"
        :placeholder="$t('organizations.placeholderName')"
        class="mb-1"
        :isValid="isValid.hostname"
        :tooltipFeedback="false"
        :invalidFeedback="validationMessages.required"
        @change="$v.peerData.hostname.$touch()"
      >
        <template #append-content>
          <span> {{ org.domain || "empty.com" }}</span>
        </template>
      </CInput>

      <CInput
        v-model="peerData.port"
        horizontal
        label="Port*"
        :placeholder="$t('organizations.placeholderPort')"
        class="mb-1"
        :isValid="isValid.port"
        :tooltipFeedback="false"
        :invalidFeedback="validationMessages.required"
        @change="$v.peerData.port.$touch()"
      ></CInput>

      <div slot="footer" class="footer-buttons">
        <CButton type="button" class="btn btn-secondary" @click="closeModal">
          {{ $t("common.cancel") }}
        </CButton>
        <CButton
          type="button"
          class="btn btn-primary"
          @click="createPeer"
          :disabled="!canCreate"
        >
          {{ $t("common.create") }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import utilMessages from "../../../../utils/messages";
import messages from "../../messages";

import { EndpointTypes } from "./constants";

export default {
  name: "PeerForm",
  props: {
    org: {
      type: Object,
      required: true,
    },
    netId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      endpointTypes: EndpointTypes,
      validationMessages: messages.validation,

      isPeerFormShowed: false,

      peerData: {
        org_id: this.org.id,
        blockchain_net_id: this.netId,
        type: EndpointTypes.peer.code,
        hostname: null,
        port: null,
      },
    };
  },
  validations: {
    peerData: {
      hostname: {
        required,
      },
      port: {
        required,
      },
    },
  },

  computed: {
    isValid() {
      let hostname, port;
      if (this.$v.peerData.hostname.$dirty)
        hostname = !this.$v.peerData.hostname.$invalid;

      if (this.$v.peerData.port.$dirty) port = !this.$v.peerData.port.$invalid;

      return {
        hostname: hostname,
        port: port,
      };
    },

    canCreate() {
      return !this.$v.peerData.$invalid;
    },
  },

  methods: {
    init() {
      this.peerData.hostname = null;
      this.peerData.port = null;

      this.$v.$reset();
    },

    addPeer() {
      this.init();
      this.isPeerFormShowed = true;
    },

    async createPeer() {
      try {
        const data = {
          ...this.peerData,
          hostname: [
            this.peerData.hostname.replace(/\s+/gm, ""),
            this.org.domain,
          ].join("."),
        };
        const peer = await this.$store.dispatch("createEndpoint", data);
        console.log(peer);

        this.$emit("createdPeer", peer);
        this.isPeerFormShowed = false;
        this.$toast.success(this.$i18n.t("peer.created"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    closeModal() {
      this.isPeerFormShowed = false;
      this.$emit("cancelPeer");
    },
  },
};
</script>

<style scoped>
button {
  margin-left: 5px;
}

.organization__buttons {
  display: flex;
  gap: 1rem;

  align-items: center;
  justify-content: flex-end;
}
</style>
