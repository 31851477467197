<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div class="card-item d-flex flex-column mb-3">
    <div class="d-flex w-100 align-items-center no-padding flex-nowrap">
      <div class="d-flex flex-column">
        <div class="host-name mb-2 d-flex align-items-center">
          <span v-c-tooltip="fullHostName" class="">{{ fullHostName }}</span>
          <img
            v-if="host.is_anchor"
            class="ml-1"
            src="/img/anchor_icon.svg"
            width="14"
            height="14"
            alt=""
          />
        </div>
        <div v-if="!host.ip_address || host.ip_address === 'global'">
          <div v-if="!addIP">
            <span class="mr-4">
              <fa-icon icon="globe" size="sm" /> Global DNS
            </span>
            <CLink @click="addIP = true">{{ $t("common.indicate") }} IP</CLink>
          </div>
          <div v-else>
            <CInput
              type="text"
              :placeholder="$t('common.ip')"
              class="mb-0"
              :value="hostData.ip_address"
              @input="changeIP"
              ref="inputIP"
            >
              <template #append v-if="hostData.ip_address">
                <CButton type="submit" color="success" @click="saveHost()">{{
                  $t("common.save")
                }}</CButton>
              </template>
              <template #append v-else>
                <CButton
                  type="submit"
                  color="primary"
                  @click="addIP = !addIP"
                  >{{ $t("common.cancel") }}</CButton
                >
              </template>
            </CInput>
          </div>
        </div>
        <div v-else>
          IP: {{ host.ip_address }}
          <span class="remove-icon" @click="deleteHost(host.endpoint_id)"
            >×</span
          >
        </div>
      </div>
      <CButton
        v-if="!addIP"
        class="remove-button"
        variant="ghost"
        @click="removePeer(host)"
      >
        <CIcon name="cil-trash" size="sm" />
      </CButton>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import utilMessages from "../../../utils/messages";

export default {
  name: "OrgNetCardHost",
  components: {},
  data() {
    return {
      addIP: false,
      endpoint: null,
      topicId: null,
      connected: false,

      hostData: {
        blockchain_net_id: this.netId,
        endpoint_id: this.host.endpoint_id,
        ip_address: null,
      },

      prevIP: null,
    };
  },
  props: {
    netId: {
      type: String,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
  },
  async mounted() {},
  watch: {},
  computed: {
    ...mapGetters(["allEndpoints"]),
    fullHostName() {
      const hostPort =
        this.host.endpoint_port !== "" ? `: ${this.host.endpoint_port}` : "";
      return `${this.host.endpoint_hostname}${hostPort}`;
    },
  },
  methods: {
    ...mapActions(["updateIsUpdateHostsAvailable", "deleteEndpointFromServer"]),
    async saveHost() {
      try {
        await this.$store.dispatch("postExtraHost", this.hostData);
        this.$toast.success(this.$i18n.t("network.ipSaved"));
        this.addIP = false;
        this.updateIsUpdateHostsAvailable(true);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async deleteHost(endpointId) {
      try {
        await this.$store.dispatch("deleteExtraHost", {
          endpointId,
          netId: this.netId,
        });
        this.hostData.ip_address = null;
        this.addIP = false;
        this.$toast.success(this.$i18n.t("network.ipDeleted"));
        this.updateIsUpdateHostsAvailable(true);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    changeIP(val) {
      function clearFunc(val) {
        let newVal = "";
        const pattern = /^[0-9.]$/;
        for (let char of val) {
          if (pattern.test(char)) newVal += char;
        }

        return newVal;
      }

      if (val !== this.hostData.ip_address) {
        let isCorrect = true;
        let clearVal = clearFunc(val);
        let parts = clearVal.split(".");

        //const fullRe = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        const partRe = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        const nextPartRe = /^\d*$/;
        if (parts.length > 4) {
          this.$refs.inputIP.$set(
            this.$refs.inputIP.$data,
            "state",
            this.hostData.ip_address
          );
        } else {
          for (let part of parts) {
            if (part && !partRe.exec(part)) {
              if (
                parts.length < 4 &&
                nextPartRe.exec(part) &&
                partRe.exec(part.slice(0, part.length - 1))
              )
                this.$set(
                  this.hostData,
                  "ip_address",
                  [
                    ...parts.slice(0, parts.length - 1),
                    part.slice(0, part.length - 1),
                    part.slice(part.length - 1),
                  ].join(".")
                );

              this.$refs.inputIP.$set(
                this.$refs.inputIP.$data,
                "state",
                this.hostData.ip_address
              );
              isCorrect = false;
            }
          }

          if (isCorrect) {
            this.$set(this.hostData, "ip_address", clearVal);
          }
        }
      }
    },
    async removePeer(host) {
      try {
        const response = await this.deleteEndpointFromServer(host.endpoint_id);
        if (response) {
          await this.$store.dispatch("fetchExtraHosts", this.netId);
        }
        this.$toast.success(this.$root.$t("network.endpointRemoved"));
        this.updateIsUpdateHostsAvailable(true);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
  },
};
</script>

<style scoped>
.remove-button {
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;

  margin-left: auto;
}

.remove-icon {
  color: red;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.host-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;

  display: inline-block;
}

.card-item {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
}
</style>
