<template>
  <CModal :show="isOpened">
    <div slot="header">
      <h5 class="modal-title">
        {{ $t("organizations.anchorPeerManagement") }}
      </h5>
    </div>

    <form ref="anchorPeersForm">
      <div class="hauberk-form-group">
        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="networkAdmin">Orderer*</label>
          </div>

          <CSelect
            class="hb-item__input"
            :options="ordererForSelect"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.orderer"
            :isValid="isValid.orderer"
            :tooltipFeedback="false"
            @change="$v.form.orderer.$touch()"
          />
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="networkAdmin"
              >Fabric-admin-tool*</label
            >
          </div>

          <CSelect
            class="hb-item__input"
            :options="fabricAdminToolForSelect"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.fabricAdminTool"
            :isValid="isValid.fabricAdminTool"
            :tooltipFeedback="false"
            @change="$v.form.fabricAdminTool.$touch()"
          />
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="networkAdmin">Peer*</label>
          </div>

          <CSelect
            class="hb-item__input"
            :options="peerForSelect"
            :horizontal="{ label: 'col-sm-5', input: 'd-flex w-100' }"
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.peer"
            :isValid="isValid.peer"
            :tooltipFeedback="false"
            :invalidFeedback="validationMessages.required"
            @change="$v.form.peer.$touch()"
          />
        </div>

        <div class="hauberk-form-item hb-item hb-item_small d-flex">
          <div class="hb-item__label hb-label d-flex flex-column">
            <label class="hb-label__main" for="networkAdmin">{{
              $t("organizations.anchorPeers")
            }}</label>
          </div>

          <div class="hb-item__input">
            <hauberk-select
              :options="anchorPeers"
              v-model="form.selectedPeers"
              :placeholder="$t('organizations.selectAnchorPeers')"
            />
          </div>
        </div>
      </div>
    </form>

    <div slot="footer" class="d-flex justify-content-end gap-1">
      <CButton type="button" class="btn btn-secondary" @click="closeModal">
        {{ $t("common.cancel") }}
      </CButton>
      <CButton
        type="button"
        class="btn btn-primary"
        @click="saveAnchorPeers"
        :disabled="!isFormValid"
      >
        {{ $t("common.save") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import utilMessages from "../../../utils/messages";
import HauberkSelect from "../common/HauberkSelect.vue";
import messages from "../messages";

export default {
  name: "AnchorPeersModal",
  components: { HauberkSelect },
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    extraHosts: {
      type: Array,
      default: [],
    },
    networkId: {
      type: String,
      required: true,
    },
  },
  validations: {
    form: {
      orderer: {
        required,
      },
      fabricAdminTool: {
        required,
      },
      peer: {
        required,
      },
      selectedPeers: {
        required,
      },
    },
  },
  data() {
    return {
      ordererList: [],
      fabricAdminToolList: [],
      peerList: [],
      validationMessages: messages.validation,
      form: {
        orderer: null,
        fabricAdminTool: null,
        peer: null,
        selectedPeers: [],
      },
    };
  },
  async mounted() {
    await this.getFabricAdminTool();
    await this.getOrderer();
    await this.getPeer();
  },
  computed: {
    anchorPeers() {
      return this.extraHosts.map((item) => ({
        label: `${item.endpoint_hostname}:${item.endpoint_port}`,
        value: item.endpoint_id,
      }));
    },
    ordererForSelect() {
      return this.ordererList.map((item) => {
        return {
          label: item.hostname,
          value: item.id,
        };
      });
    },
    fabricAdminToolForSelect() {
      return this.fabricAdminToolList.map((item) => {
        return {
          label: item.hostname,
          value: item.id,
        };
      });
    },
    peerForSelect() {
      return this.peerList.map((item) => {
        return {
          label: item.hostname,
          value: item.id,
        };
      });
    },
    isValid() {
      let orderer, fabricAdminTool, peer, selectedPeers;

      if (this.$v.form.orderer.$dirty) {
        orderer = !this.$v.form.orderer.$invalid;
      }

      if (this.$v.form.fabricAdminTool.$dirty) {
        fabricAdminTool = !this.$v.form.fabricAdminTool.$invalid;
      }

      if (this.$v.form.peer.$dirty) {
        peer = !this.$v.form.peer.$invalid;
      }

      if (this.$v.form.selectedPeers.$dirty) {
        selectedPeers = !this.$v.form.selectedPeers.$invalid;
      }

      return {
        orderer,
        fabricAdminTool,
        peer,
        selectedPeers,
      };
    },
    isFormValid() {
      return !this.$v.form.$invalid;
    },
  },
  methods: {
    ...mapActions(["updateAnchorPeers", "fetchEndpoints"]),
    async saveAnchorPeers() {
      try {
        await this.updateAnchorPeers({
          payload: this.form,
          netId: this.networkId,
        });
        this.$toast.info(this.$i18n.t("organizations.anchorPeersUpdating"));
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      } finally {
        this.closeModal();
      }
    },
    async getOrderer() {
      try {
        this.ordererList = await this.fetchEndpoints(
          `blockchain_net_id=${this.networkId}&type=orderer`
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getFabricAdminTool() {
      try {
        this.fabricAdminToolList = await this.fetchEndpoints(
          "status=attached&current_org=true&type=fabric-admin-tools"
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getPeer() {
      try {
        this.peerList = await this.fetchEndpoints("type=peer&current_org=true");
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    closeModal() {
      this.$refs.anchorPeersForm.reset();
      this.$v.$reset();

      this.$emit("close");
    },
  },
  watch: {
    isOpened: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.form.selectedPeers = this.extraHosts
          .filter((item) => item.is_anchor)
          .map((item) => item.endpoint_id);
      },
    },
  },
};
</script>

<style lang="css">
.form-item {
  display: flex;
  flex-direction: column;
}

.form__label {
  font-size: 0.9rem;
  font-weight: 600;

  margin-bottom: 0.5rem;
}
</style>
