<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    
    <div class="form-item">
      <span class="form-item__label">MSP ID:</span>
      <span class="form-item__value">{{ org.mspid }}</span>
    </div>
    
    <CLink
      @click="certsCollapse = !certsCollapse"
      color="primary"
      class="d-block mb-1"
    >
      {{ $t("network.certificates") }}
      <fa-icon icon="angle-down" size="sm" v-if="!certsCollapse" class="ml-1" />
      <fa-icon icon="angle-up" size="sm" v-if="certsCollapse" class="ml-1" />
    </CLink>
    <CCollapse :show="certsCollapse" :duration="400">
      <CCard body-wrapper>
        <div class="row mb-3">
          <strong class="col-sm-3">{{ $t("network.ca") }}</strong>
          <span class="col-sm-9">
            <certificate
              v-for="(certId, i) in org.msp[certTypes.cacerts.code]"
              :org-id="org.id"
              :type-of="certTypes.cacerts.code"
              :cert-id="certId"
              :text-if-is="true"
              :key="i"
            ></certificate>
          </span>
        </div>

        <div
          class="row mb-3"
          v-for="(certId, i) in org.msp[certTypes.intermediatecerts.code]"
          :key="i"
        >
          <strong class="col-sm-3"
            >{{ $t("network.intermediate") }} {{ i + 1 }}</strong
          >
          <span class="col-sm-9">
            <certificate
              :org-id="org.id"
              :type-of="certTypes.intermediatecerts.code"
              :cert-id="certId"
              :text-if-is="true"
            ></certificate>
          </span>
        </div>

        <div class="row mb-3">
          <strong class="col-sm-3">{{ $t("network.tlsca") }}</strong>
          <span class="col-sm-9">
            <certificate
              v-for="(certId, index) in org.msp[certTypes.tlscacerts.code]"
              :org-id="org.id"
              :type-of="certTypes.tlscacerts.code"
              :cert-id="certId"
              :text-if-is="true"
              :key="index"
            ></certificate>
            <!--<CLink :href="org.tlsca.path" target="_blank">{{org.tlsca.cn}}</CLink> ({{org.tlsca.fingerprint}})-->
          </span>
        </div>

        <div
          class="row mb-3"
          v-for="(certId, i) in org.msp[certTypes.tlsintermediatecerts.code]"
          :key="i"
        >
          <strong class="col-sm-3"
            >{{ $t("network.intermediateTls") }} {{ i + 1 }}</strong
          >
          <span class="col-sm-9">
            <certificate
              :org-id="org.id"
              :type-of="certTypes.tlsintermediatecerts.code"
              :cert-id="certId"
              :text-if-is="true"
            ></certificate>
          </span>
        </div>

        <div
          class="row mb-3"
          v-for="(certId, i) in org.msp[certTypes.admincerts.code]"
          :key="i"
        >
          <strong class="col-sm-3"
            >{{ $t("network.admin") }} {{ i + 1 }}</strong
          >
          <span class="col-sm-9">
            <certificate
              :org-id="org.id"
              :type-of="certTypes.admincerts.code"
              :cert-id="certId"
              :text-if-is="true"
            >
            </certificate>
          </span>
        </div>
      </CCard>
    </CCollapse>

    <CLink
      @click="orgnetCollapse = !orgnetCollapse"
      color="primary"
      class="d-block mt-3 mb-1"
    >
      {{ isForChannels ? $t("network.peerList") : $t("network.networkCard") }}
      <fa-icon
        icon="angle-down"
        size="sm"
        v-if="!orgnetCollapse"
        class="ml-1"
      />
      <fa-icon icon="angle-up" size="sm" v-if="orgnetCollapse" class="ml-1" />
    </CLink>
    <CCollapse :show="orgnetCollapse" :duration="400">
      <CCard body-wrapper class="mb-0">
        <OrgNetCardHost
          v-for="(host, index) in hosts"
          :net-id="netId"
          :host="host"
          :key="index"
        />
        <div class="d-flex justify-content-end gap-1">
          <CLink
            v-if="org && !ordererOrganization"
            @click="setAnchorPeerVisible(true)"
            class="float-right"
          >
            {{ $t("organizations.anchorPeerManagement") }}
          </CLink>

          <PeerForm
            v-if="org && !ordererOrganization"
            :org="org"
            :net-id="netId"
            @createdPeer="createdPeer"
          >
          </PeerForm>
        </div>
      </CCard>
    </CCollapse>

    <AnchorPeersModal
      :isOpened="anchorPeersModalVisible"
      :extraHosts="hosts"
      :networkId="netId"
      @close="setAnchorPeerVisible(false)"
    />
  </div>
</template>

<script>
import { enpointsTypes } from "../../../const/networkConsts";
import utilMessages from "../../../utils/messages";
import Certificate from "../certificates/Certificate";
import { CertificateTypes } from "../certificates/constants";
import { EndpointTypes } from "../organizations/endpoints/constants";
import PeerForm from "../organizations/endpoints/PeerForm";
import AnchorPeersModal from "./AnchorPeersModal.vue";
import OrgNetCardHost from "./OrgNetCardHost.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "NetworkOrg",
  components: {
    PeerForm,
    Certificate,
    OrgNetCardHost,
    AnchorPeersModal,
  },
  data() {
    return {
      certTypes: CertificateTypes,
      endpointTypes: EndpointTypes,
      peers: [],
      certsCollapse: false,
      orgnetCollapse: true,
      anchorPeersModalVisible: false,
    };
  },
  props: {
    netId: {
      type: String,
      required: true,
    },
    org: {
      type: Object,
      required: true,
    },
    orgHosts: {
      type: Array,
    },
    ordererOrganization: Boolean,
    isForChannels: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  computed: {
    ...mapGetters(["allEndpoints"]),
    hosts() {
      if (!this.orgHosts.length) {
        return;
      }

      const endpoints = new Map(
        this.allEndpoints.map((item) => [item.id, item.type])
      );

      return this.orgHosts.filter(
        (item) =>
          endpoints.has(item.endpoint_id) &&
          endpoints.get(item.endpoint_id) !== enpointsTypes.fabricAdminTools
      );
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["updateIsUpdateHostsAvailable"]),
    async fetchHosts() {
      try {
        await this.$store.dispatch("fetchExtraHosts", this.netId);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async createdPeer(peer) {
      this.updateIsUpdateHostsAvailable(true);
      await this.fetchHosts();
    },
    async createdFabric(fabric) {
      console.log("createdFabric: ", fabric);
      await this.fetchHosts();
    },
    setAnchorPeerVisible(visibility) {
      this.anchorPeersModalVisible = visibility;
    },
  },
};
</script>

<style lang="css">
.form-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.form-item__label {
  font-weight: 700;
  margin-right: 10px;
}
</style>
