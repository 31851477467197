<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<template>
  <div class="d-inline-block">
    <CModal
      :show="show"
      :closeOnBackdrop="false"
      :closeButton="false"
      @close="closeModal"
      size="xl"
    >
      <template slot="header">
        <h5 class="modal-title">{{$t('network.updatingNetworkConfiguration')}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>

      <CCard>
        <CCardBody class="my-test">
          <div class="loading-overlay" v-if="isImporting">
            <div class="d-flex align-items-center justify-content-center">
              <fa-icon
                icon="sync"
                size="1x"
                class="animated mr-2"
                style="color: black"
              />
              <span class="gray-text"> {{ $t("common.processing") }}... </span>
            </div>
          </div>

          <div role="group" class="form-group form-row">
            <label class="col-form-label col-sm-3 font-weight-bold">
              {{ $t("network.networkConfiguration") }}*
            </label>
            <div class="col-sm-9">
              <b-form-file
                v-model="form.file"
                :placeholder="$t('network.selectNetworkConfiguration')"
                lang="ru"
                class="mb-3"
                required
                @change="fileChange"
                :browse-text="$t('common.browse')"
              ></b-form-file>
            </div>
          </div>
        </CCardBody>
      </CCard>

      <div slot="footer" class="footer-buttons">
        <CButton
          type="button"
          class="btn btn-secondary mr-1"
          :disabled="isImporting"
          @click="closeModal"
        >
          {{ $t("common.cancel") }}
        </CButton>
        <CButton
          :disabled="!canImport || isImporting"
          type="button"
          class="btn btn-primary"
          @click="importConfiguration"
        >
          {{ $t("network.updateNetwork") }}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { BFormFile } from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { readFileAsText } from "../../../utils/files";

import utilMessages from "@/utils/messages";
import messages from "@/views/hauberk/messages";

export default {
  name: "ImportNetworkConfiguration",
  components: {
    BFormFile,
  },
  props: {
    name: String,
    show: Boolean,
    networkId: String,
  },
  data() {
    return {
      validationMessages: messages.validation,
      isImporting: false,
      fileContent: "",
      form: {
        file: null,
      },
    };
  },  
  validations: {
    form: {
      file: {
        required,
      },
    },
  },
  computed: {
    canImport() {
      return !this.$v.form.file.$invalid;
    },
  },
  methods: {
    ...mapActions(["importNetwork"]),
    async fileChange($event) {
      try {
        this.fileLoading = true;
        if (!$event.target.files[0]) {
          return;
        }

        this.form.file = $event.target.files[0];
        this.fileContent = await readFileAsText(this.form.file);
        const configuration = JSON.parse(this.fileContent);

        if (!configuration.id) {
          this.$toast.error(this.$i18n.t("network.networkIdNotFound"));
          this.clearState();
          return;
        }

        if (configuration.id !== this.networkId) {
          this.$toast.error(this.$i18n.t("network.wrongNetworkConfiguration"));
          this.clearState();
          return;
        }
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      } finally {
        this.fileLoading = false;
      }
    },
    async importConfiguration() {
      try {
        this.isImporting = true;
        await this.importNetwork(JSON.parse(this.fileContent));
        this.$toast.success(this.$i18n.t("network.configurationUpdated"));
        this.closeModal();
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      } finally {
        this.isImporting = false;
      }
    },
    closeModal() {
      this.clearState();
      this.$emit("closeModal");
    },
    clearState() {
      this.form = {};
      this.fileContent = null;
      this.$v.$reset();
    },
  },
};
</script>

<style scoped>
.gray-text {
  color: #768192;
}

.diff-placeholder {
  height: 70px;
  border: 1px dashed #dedede;
}

.my-test {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;

  background-color: rgba(0, 0, 0, 0.2);
}
</style>
